import React from "react";

const FeatureComponent: React.FC<{
  logo: string;
  alt: string;
  title: string;
  text: string;
}> = ({ alt, logo, text, title }) => {
  return (
    <div className="feature-component--wrapper">
      <img
        src={logo}
        alt={alt}
        title={alt}
        className="feature-component--icon"
      />
      <div className="feature-component--text">
        <h3>{title}</h3>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default FeatureComponent;
