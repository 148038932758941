import React, { useState } from "react";
import LayoutComponent from "../components/LayoutComponent";
import "@fontsource/montserrat";
import "../styles/pages.scss";
import WelcomeMockup from "../assets/images/mockup.png";
import Price from "../assets/images/price.png";
import FeatureComponent from "../components/FeatureComponent";
import FastIcon from "../assets/icons/fast-icon.svg";
import LockIcon from "../assets/icons/lock-icon.svg";
import EasyIcon from "../assets/icons/easy-icon.svg";
import DevicesIcon from "../assets/icons/devices-icon.svg";
import Seo from "../components/seo";

const IndexPage: React.FC<{}> = () => {
  const resetData = (): { mail: string; name: string; company: string } => ({
    company: "",
    mail: "",
    name: "",
  });
  const [contactInfo, setContactInfo] = useState<{
    mail: string;
    name: string;
    company: string;
  }>(resetData());

  return (
    <LayoutComponent>
      <Seo title="Home" />
      <div className="welcome--wrapper">
        <div className="welcome--text">
          <h1>
            Erfasse den 3G Status deiner Mitarbeiter mit <span>CORACK</span>
          </h1>
          <small>
            Das einfache und schnelle Erfassen des 3G-Status deiner Mitarbeiter
            ist wichtig. CORACK ermöglicht dir, dich auf dein eigentliches
            Geschäft zu konzentrieren und dennoch das gute Gefühl zu haben alles
            im Blick zu haben!
          </small>
        </div>
        <div className="welcome--image">
          <img
            src={WelcomeMockup}
            alt="Corack App Mockup"
            title="So sieht CORACK in Betrieb aus"
          />
        </div>
        <div id="function">
          <h2>Funktionen</h2>
          <div className="function--wrapper">
            <FeatureComponent
              alt="CORACK ist schnell"
              logo={FastIcon}
              title="Schnell"
              text="Die Status-Erfassung geht schnell - so können deine Mitarbeiter sich wieder ihrer eigentlichen Arbeit widmen und du hast das gute Gefühl dass alles sicher erfasst ist."
            />
            <FeatureComponent
              alt="CORACK ist sicher"
              logo={LockIcon}
              title="Sicher"
              text="Die erfassten Werte sind sicher auf deutschen Servern verschlüsselt abgelegt. Datenschutzkonform werden sie nach 6 Monaten automatisch gelöscht."
            />
            <FeatureComponent
              alt="CORACK ist einfach"
              logo={EasyIcon}
              title="Einfach"
              text="Keine unnötigen Features, keine überladenen Menüs - CORACK ist einfach aufgebaut und macht Spaß zu bedienen. Auch eine Integration in Bestandssysteme zur Auswertung ist möglich."
            />
            <FeatureComponent
              alt="CORACK ist überall verfügbar"
              logo={DevicesIcon}
              title="Überall"
              text="CORACK ist durch Web-Technologien auf Mobil und Desktop verfügbar. So brauchst du keine besondere Hardware, sondern kannst CORACK sofort benutzen."
            />
          </div>
        </div>
        <div id="price">
          <h2>Preis</h2>
          <div className="price--wrapper">
            <p className="price--text">
              CORACK ist einfach und unkompliziert, das hört beim Preis nicht
              auf: es gibt keine komplizierten Preis- oder Abo-Modelle. Pro
              Mitarbeiter erheben wir einen Preis und du kannst jeden Monat
              deine Mitgliedschaft beenden.
            </p>
            <img
              className="price--amount"
              src={Price}
              alt="CORACK Preis"
              title="Ein einfacher Preis"
            />
          </div>
        </div>
        <div id="contact">
          <h2>Kontakt</h2>
          <div className="contact--wrapper">
            <form
              className="contact--form"
              onSubmit={(evt) => {
                evt.preventDefault();
                fetch("/php/mail.php", {
                  method: "POST",
                  body: JSON.stringify(contactInfo),
                })
                  .then((result) => {
                    return result.json();
                  })
                  .then((response) => {
                    if (response.status === "ok") {
                      setContactInfo(resetData());
                    }
                  });
              }}
            >
              <input
                value={contactInfo.name}
                required
                onChange={(evt) =>
                  setContactInfo({ ...contactInfo, name: evt.target.value })
                }
                placeholder="Dein Name *"
              />
              <input
                value={contactInfo.mail}
                type="email"
                required
                onChange={(evt) =>
                  setContactInfo({ ...contactInfo, mail: evt.target.value })
                }
                placeholder="Deine Mail-Adresse *"
              />
              <input
                value={contactInfo.company}
                onChange={(evt) =>
                  setContactInfo({ ...contactInfo, company: evt.target.value })
                }
                placeholder="Dein Firmenname"
              />
              <button>Anfragen</button>
            </form>
            <p className="contact--text">
              CORACK wird speziell für dich bereitgestellt. Hinterlasse uns
              einfach deine Daten und du hörst innerhalb von 24 Stunden von
              unserem Team mit deinem Account-Zugang. Bei Fragen und Feedback
              kannst du dich natürlich jederzeit bei uns melden!
            </p>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default IndexPage;
